import ErrorBoundary from '@/pages/ErrorBoundary';
import PageLoading from '@/pages/PageLoading';
import { type PropifyRoute, routes } from '@/routes';
import { SentryService } from '@propify-auth-client/services';
import { type FC, Suspense, useMemo } from 'react';
import { Navigate, Outlet, type RouteObject, RouterProvider } from 'react-router';

const generateRoutes: (routesList: PropifyRoute[]) => RouteObject[] = (routesList) =>
  routesList.map((route) => ({
    path: route.path,
    element: route.redirect ? (
      <Navigate to={route.redirect} />
    ) : route.component ? (
      <Suspense fallback={<PageLoading />}>
        <route.component route={route} {...route.props} />
      </Suspense>
    ) : (
      <Outlet />
    ),
    children: !route.redirect && route.routes ? generateRoutes(route.routes) : undefined,
    ErrorBoundary,
  }));

export const Router: FC = () => {
  const router = useMemo(
    () =>
      SentryService.sentryCreateBrowserRouter([
        {
          path: '/',
          element: <Outlet />,
          children: generateRoutes(routes),
          ErrorBoundary,
        },
      ]),
    [],
  );

  return <RouterProvider router={router} />;
};
